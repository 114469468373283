import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { AsyncBelongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import { query } from 'ember-data-resources';
import { TableColumn } from 'teamtailor/components/table/container';
import { DivisionModel, ImageModel } from 'teamtailor/models';
import Current from 'teamtailor/services/current';
import { get } from 'teamtailor/utils/get';

export default class IndexController extends Controller {
  @service declare current: Current;

  @tracked search = '';
  @tracked page = 1;

  @tracked sort_column = 'name';
  @tracked sort_direction = 'asc';

  queryParams = ['search', 'page', 'sort_direction'];

  get divisions() {
    return this.divisionsQuery.records;
  }

  get tableColumns() {
    const columns: TableColumn[] = [
      {
        label: 'common.division',
        sortable: true,
        sortColumn: 'name',
        defaultSortOrder: 'asc',
        sortOrder: 1,
      },
      {
        label: 'user.manager',
        sortable: false,
        sortOrder: 2,
      },
    ];

    return columns;
  }

  get anyDivisionHasLogo() {
    return this.divisions?.some((division: DivisionModel) => division.logo);
  }

  get design() {
    return get(this.current.company, 'design');
  }

  get careersiteLogo(): AsyncBelongsTo<ImageModel> {
    return get(this.design, 'careersiteLogo');
  }

  get logotype(): AsyncBelongsTo<ImageModel> | null {
    return get(this.design, 'logotype');
  }

  get companyLogo(): AsyncBelongsTo<ImageModel> | null {
    return this.logotype ?? this.careersiteLogo;
  }

  handleSearchInput = restartableTask(async (value) => {
    await timeout(1000);
    this.search = value;
    this.page = 1;
  });

  divisionsQuery = query(this, 'division', () => {
    return {
      name: this.search,
      sort_column: this.sort_column,
      sort_direction: this.sort_direction,
      no_no_division: true,
    };
  });
}

declare module '@ember/controller' {
  interface Registry {
    'settings.organization.divisions.index': IndexController;
  }
}
