/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { trackedFunction } from 'ember-resources/util/function';
import { gql } from '@apollo/client/core';
import { inject as service } from '@ember/service';
import type CandidateModel from 'teamtailor/models/candidate';
import type JobApplicationModel from 'teamtailor/models/job-application';
import type AboardApolloService from 'teamtailor/services/aboard-apollo';
import Current from 'teamtailor/services/current';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import RouteHelper from 'teamtailor/services/route-helper';
import { tracked } from '@glimmer/tracking';
import { Transition } from 'teamtailor/utils/type-utils';

interface Args {
  candidate: CandidateModel;
  jobApplication: JobApplicationModel;
}

interface AboardOnboardingSummary {
  totalSignedDocuments: number;
  totalDocuments: number;
  sentMessages: number;
  totalForms: number;
  totalMessages: number;
  totalPercentage: number;
  totalTasks: number;
  answeredForms: number;
  completedTasks: number;
  profileCompletionPercentage: number;
}

interface AboardQueryResult {
  company: {
    onboarding: {
      id: string;
      position: {
        startDate: string;
      };
      summary: AboardOnboardingSummary;
    };
  };
}

interface ProcessedAboardData {
  startDate: string;
  totalPercentage: number;
  totalProgress: number;
  completedTasks: number;
  totalTasks: number;
  totalTasksProgress: number;
  sentMessages: number;
  totalMessages: number;
  totalMessagesProgress: number;
  answeredForms: number;
  totalForms: number;
  totalFormsProgress: number;
  totalSignedDocuments: number;
  totalDocuments: number;
  totalDocumentsProgress: number;
  profileCompletionPercentage: number;
  profileCompletionProgress: number;
}

export default class OnboardingComponent extends Component<Args> {
  @service declare aboardApollo: AboardApolloService;
  @service declare current: Current;
  @service declare router: RouterService;
  @service declare routeHelper: RouteHelper;

  @tracked parentRouteVersion: number = 0;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.router.on('routeWillChange', this.handleRouteChange);
  }

  willDestroy() {
    super.willDestroy();
    this.router.off('routeWillChange', this.handleRouteChange);
  }

  @action
  handleRouteChange(transition: Transition) {
    const { from } = transition;

    if (from?.name.includes('aboard-lite')) {
      this.parentRouteVersion++;
    }
  }

  aboardData = trackedFunction(
    this,
    async (): Promise<ProcessedAboardData | undefined> => {
      this.parentRouteVersion;

      const query = gql`
        query GetOnboardingData($id: ID!) {
          company {
            onboarding(id: $id) {
              id
              position {
                startDate
              }
              summary {
                answeredForms
                completedTasks
                sentMessages
                totalForms
                totalMessages
                totalPercentage
                totalTasks
                totalDocuments
                totalSignedDocuments
                profileCompletionPercentage
              }
            }
          }
        }
      `;

      try {
        const result = await this.aboardApollo.query<AboardQueryResult>({
          query,
          variables: { id: this.args.jobApplication.aboardOnboardingId },
          fetchPolicy: 'network-only',
        });

        const { startDate } = result.company.onboarding.position;
        const { summary } = result.company.onboarding;

        return {
          startDate: new Date(startDate).toLocaleDateString(
            this.current.locale,
            {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            }
          ),

          totalPercentage: summary.totalPercentage,
          totalProgress: summary.totalPercentage / 100,
          completedTasks: summary.completedTasks,
          totalTasks: summary.totalTasks,
          totalTasksProgress: summary.completedTasks / summary.totalTasks,
          sentMessages: summary.sentMessages,
          totalMessages: summary.totalMessages,
          totalMessagesProgress: summary.sentMessages / summary.totalMessages,
          totalDocumentsProgress:
            summary.totalSignedDocuments / summary.totalDocuments,
          totalSignedDocuments: summary.totalSignedDocuments,
          totalDocuments: summary.totalDocuments,
          answeredForms: summary.answeredForms,
          totalForms: summary.totalForms,
          totalFormsProgress: summary.answeredForms / summary.totalForms,
          profileCompletionPercentage: summary.profileCompletionPercentage,
          profileCompletionProgress: summary.profileCompletionPercentage / 100,
        };
      } catch {
        return undefined;
      }
    }
  );

  @action
  openAboardLite() {
    const url = this.routeHelper.getNestedRoute('aboard-lite');

    if (url && this.args.jobApplication.aboardOnboardingId) {
      this.router.transitionTo(
        url,
        this.args.jobApplication.aboardOnboardingId
      );
    }
  }
}
