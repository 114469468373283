import Helper from '@ember/component/helper';
import { FilterOption, FilterType } from 'teamtailor/components/fancy-filters';
import {
  DEFAULT_STAGE_ICON,
  STAGE_TYPE_ICONS,
} from 'teamtailor/constants/stage-type-icons';

export function filterOrColumnIconName([option]: FilterOption[]): string {
  if (option?.icon) {
    return option.icon;
  }

  switch (option?.name) {
    case 'title':
    case 'internal_name':
      return 'pencil';

    case 'inbox_count':
      return STAGE_TYPE_ICONS.inbox;

    case 'in_process_count':
      return DEFAULT_STAGE_ICON;

    case 'screening_count':
      return STAGE_TYPE_ICONS.screening;

    case 'interview_count':
      return STAGE_TYPE_ICONS.interview;

    case 'offer_count':
      return STAGE_TYPE_ICONS.offer;

    case 'rejected_count':
      return STAGE_TYPE_ICONS.rejected;

    case 'hired_count':
      return STAGE_TYPE_ICONS.hired;

    case 'internal':
      return 'house-user';

    case 'email':
      return 'at';

    case 'division_id':
      return 'list-tree';
    case 'department_id':
      return 'building';
    case 'candidate_department_id':
    case 'job_department_id':
      return 'users';

    case 'location_id':
    case 'page_visits_geolocation_country':
    case 'page_visits_geolocation_city':
      return 'location-dot';

    case 'promotion':
    case 'promotion_channel':
      return 'bullhorn';

    case 'phone':
      return 'phone-flip';

    case 'source':
    case 'job_application_referring_site':
    case 'candidate_referring_site':
    case 'page_visits_path':
    case 'page_visits_page':
    case 'referring_url':
    case 'url':
      return 'link';

    case 'name_or_email':
    case 'first_name':
    case 'last_name':
      return 'circle-user';

    case 'resume_text':
    case 'pitch':
    case 'cover_letters':
    case 'roles':
    case 'user':
      return 'user';

    case 'job_role_id':
      return 'briefcase-blank';

    case 'rating':
      return 'star';

    case 'select_question':
      return 'circle-question';

    case 'application_status':
      return 'list';

    case 'device_type':
      return 'laptop-mobile';

    default:
      return fetchIconForType(option?.type);
  }
}

function fetchIconForType(type: FilterType | undefined): string {
  switch (type) {
    case 'string':
      return 'font';

    case 'integer':
      return 'hashtag';

    case 'Select':
      return 'circle-caret-down';

    case '[Option]':
    case '[Alternative]':
    case '[Select]':
    case '[StageType]':
      return 'list';

    case 'bool':
      return 'square-check';

    case 'relative_date':
    case 'date':
      return 'calendar';

    case 'average_rating':
      return 'star';

    case 'application_stage_polar':
    case 'polar':
      return 'square-check';

    case '[Region]':
    case '[Location]':
      return 'location-dot';

    case '[Division]':
      return 'list-tree';
    case '[Department]':
      return 'building';
    case 'Role':
    case '[Role]':
      return 'users';

    case 'User':
      return 'user';

    case 'JobStatus':
      return 'circle-question';

    case 'RemoteStatus':
      return 'signal-stream';

    case '[Language]':
      return 'language';

    case '[CandidateTag]':
    case '[JobTag]':
      return 'tag';

    case '[ExternalRecruiter]':
      return 'house-person-return';

    case '[RecruitingFirm]':
      return 'house';

    case '[Company]':
      return 'building';

    case '[Job]':
      return 'briefcase-blank';

    case '[Requisition]':
      return 'clipboard-list';

    case 'ReviewedBy':
      return 'star';

    case 'file':
      return 'file';

    case 'range':
      return 'sliders';

    case 'radio':
    case 'container':
    default:
      return '';
  }
}

export default Helper.helper(filterOrColumnIconName);
